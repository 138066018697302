import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Image } from "../components/site/Image";
import { Button } from "../components/site/Button";

const Apps = (props) => {
  console.log(props);
  const books = props?.data?.allBooksJson?.edges;

  

  return (
    <Wrapper>
    <div className="container gx-1">
      <h1 className="fw-bold">Download Apps</h1>
      <p className="my-3 mx-2 col-lg-8">Exciting news! PuzzleBase Games is soon launching a mobile app!</p>
      <p className="my-3 mx-2 col-lg-8">
We know how important it is to have access to quality puzzles on-the-go, and that's why we're thrilled to announce that our mobile app is just around the corner. With the PuzzleBase Games mobile app, you'll be able to enjoy all of your favorite puzzles and word search games from the comfort of your smartphone or tablet.
</p>
<p className="my-3 mx-2 col-lg-8">
Our new app will feature a sleek and user-friendly design, allowing you to easily navigate and find the puzzles you love. You'll be able to access all of your favorite puzzle books and a wide variety of online puzzles, all in one convenient place. Plus, with new puzzles added regularly, you'll never run out of challenging and fun puzzles to enjoy.
</p>
<p className="my-3 mx-2 col-lg-8">
We can't wait to share our new mobile app with you! Sign up for updates and be the first to know when the PuzzleBase Games mobile app is available for download.

</p>
    </div>
    </Wrapper>
  );
};

export const query = graphql`
  # query will go here
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          shortUrl
          pages
        }
      }
    }
  }
`;

export default Apps;
